<template>
  <section :aria-label="section.headline">
    <div
      :class="{ dark: section.dark_mode }"
      :style="styledBg"
      class="
        component
        position-relative
        c-galleries
        padding-y-24
        bp-768:padding-y-48
      "
    >
      <div class="row padding-x-12 position-relative z-index-3">
        <common-header
          :headline="section.headline"
          :sponsor="section.sponsor"
        />
      </div>
      <div
        class="
          position-relative
          z-index-3
          c-galleries__container
          row
          padding-x-12
          d-block
          bp-768:display-flex
          padding-t-24 padding-b-18
        "
      >
        <div
          class="
            c-galleries__large
            width-100
            bp-768:width-50
            display-flex
            flex-wrap-wrap
          "
        >
          <div
            class="c-galleries__item width-100"
            v-for="(image, index) in gallery_images.slice(0, 1)"
            v-on:click="open_modal(image)"
            :key="image.alt + 'Large' + index"
          >
            <div class="c-galleries__item-inside">
              <img
                :src="image && image.large ? image.large : image.medium"
                :alt="image.caption + ' Large - #' + index"
              />
            </div>
          </div>
        </div>
        <div
          class="
            c-galleries__smalls
            width-100
            bp-768:width-50
            display-flex
            flex-wrap-wrap
          "
        >
          <div
            v-for="(image, index) in gallery_images.slice(1, 5)"
            :key="image.caption + index"
            v-on:click="open_modal(image)"
            class="c-galleries__item width-50"
          >
            <div class="c-galleries__item-inside">
              <img
                :src="image && image.large ? image.large : image.medium"
                :alt="image.caption + ' Large - #' + index"
              />
            </div>
          </div>
        </div>
      </div>
      <GalleryModal
        :key="renderKey"
        :selectedGallery="selectedGallery"
        :value="gallery_modal_toggled"
        v-on:input="close_gallery_modal(selectedGallery)"
      />
      <div
        v-if="this.gallery_info"
        class="row position-relative z-index-3 margin-t-24 padding-x-24"
      >
        <a
          class="
            s-common__button s-common__button-large
            margin-l-auto margin-r-auto
            c-galleries__more
            font-size-14
            line-height-125
            font-weight-700
          "
          :href="
            '/galleries/' +
            this.gallery_info.sport.slug +
            '/' +
            this.gallery_info.title_slug +
            '/' +
            this.gallery_info.id
          "
          >View Full Gallery</a
        >
      </div>
    </div>
  </section>
</template>

<script>
import SectionBase from "./SectionBase";
import Client from "@/client";
import CommonHeader from "@/components/common/CommonHeader";
import GalleryModal from "@/components/modal/GalleryModal";

export default {
  extends: SectionBase,
  name: "SectionGalleries",
  components: { CommonHeader, GalleryModal },
  data: () => ({
    renderKey: Date.now(),
    gallery_images: [],
    gallery_info: null,
    gallery_modal_toggled: false,
    selectedGallery: null,
  }),
  computed: {
    styledBg: function () {
      if (this.section.backgroundImage) {
        return {
          "background-image": "url(" + this.section.backgroundImage + ") ",
        };
      } else if (this.section.useCustomBackgroundColor) {
        return { backgroundColor: this.section.custom_background_color };
      }
      return {};
    },
  },
  methods: {
    open_modal(image) {
      this.selectedGallery = image;
      this.gallery_modal_toggled = true;
    },
    close_gallery_modal() {
      this.gallery_modal_toggled = false;
    },
    get_images() {
      Client.get("/services/galleries.ashx/gallery", {
        params: {
          id: this.section.selectedGallery,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
        },
      }).then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.images
        ) {
          this.gallery_info = response.data.data;
          if (response.data.data.images.length > 0) {
            this.gallery_images = response.data.data.images;
            this.selectedGallery = response.data.data.images[0];
          }
        }
      });
    },
  },
  created() {
    this.get_images();
  },
};
</script>

<style scoped lang="scss">
.c-galleries {
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: $main-color;
    opacity: 0.7;
    z-index: 2;
  }
  &__item {
    cursor: pointer;
    overflow: hidden;
    &-inside {
      position: relative;
      height: 0;
      padding-bottom: 100%;
      overflow: hidden;
    }
    &:hover {
      img {
        transition: 0.25s;
        transform: scale(1.05);
      }
    }
    img {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      transition: 0.25s;
      transform: scale(1);
      object-fit: cover;
      object-position: center;
    }
  }
  &__large {
    .c-galleries__item {
      border: 1px solid $main-border-color;
      box-sizing: border-box;
      border-radius: 10px 0px 0px 10px;
    }
  }
  &__smalls {
    .c-galleries__item {
      border: 1px solid $main-border-color;
      box-sizing: border-box;
      &:nth-child(2) {
        border: 1px solid $main-border-color;
        box-sizing: border-box;
        border-radius: 0px 10px 0px 0px;
      }
      &:nth-child(4) {
        border: 1px solid $main-border-color;
        box-sizing: border-box;
        border-radius: 0px 0px 10px 0px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    &__container {
      display: block !important;
    }
    &__large,
    &__smalls {
      width: 100% !important;
    }
    &__large {
      .c-galleries {
        &__item {
          border: 1px solid $main-border-color;
          box-sizing: border-box;
          border-radius: 10px 10px 0px 0px;
        }
      }
    }
    &__smalls {
      .c-galleries {
        &__item {
          border: 1px solid $main-border-color;
          box-sizing: border-box;
          border-radius: 0px !important;
          &:nth-child(3) {
            border-radius: 0px 0px 0px 10px !important;
          }
          &:nth-child(4) {
            border-radius: 0px 0px 10px 0px !important;
          }
        }
      }
    }
  }
}
</style>
